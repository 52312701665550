import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { useWindowSize, useLocation } from "react-use";
import cx from "classnames";
import { useStateContext } from "../context";

import works from "../../data/works.json";

import * as styles from "./links.module.scss";

function Links() {
  const { setidChange, idChange, nextWorkRef } = useStateContext();
  const { width, height } = useWindowSize();
  const { pathname } = useLocation();

  const widthLinksAnim = width > 1024 ? 800 : "100%";
  const widthLinksInit = width > 1024 ? 0 : 0;
  const widthLinksExit = width > 1024 ? 497 : 150;

  const oneBlockWorks = Math.ceil(works.length / 2);
  const twoBlockWorks = works.length - oneBlockWorks;

  return (
    <motion.div
      initial={{ width: widthLinksInit }}
      animate={{
        width: widthLinksAnim,
        transition: { duration: 0.6, ease: [0.37, 0.61, 0.4, 0.98] },
      }}
      exit={{
        opacity: 0,
        width: widthLinksExit,
        transition: {
          duration: 0.6,
          ease: [0.37, 0.61, 0.4, 0.98],
        },
      }}
      style={{ maxHeight: height - 85 }}
      className={styles.container}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { delay: 0.3, duration: 0.6 },
        }}
        exit={{ opacity: 0 }}
        className={styles.nav}
      >
        <div className={styles.block}>
          <div className={styles.header}>
            <p>Страницы</p>
          </div>
          <Link
            to="/"
            className={styles.link}
            activeClassName={idChange === 0 ? styles.active : null}
            onClick={() =>
              width > 1024 ? nextWorkRef.current(0) : setidChange(0)
            }
          >
            Главная
          </Link>

          <Link
            to="/"
            onClick={() =>
              width > 1024 ? nextWorkRef.current(1) : setidChange(1)
            }
            className={cx(
              styles.link,
              idChange !== 0 && pathname === "/" && styles.active
            )}
          >
            Проекты
          </Link>

          <Link
            to="/about"
            activeClassName={styles.active}
            className={styles.link}
          >
            О нас
          </Link>

          <Link
            to="/about/web"
            className={styles.link}
            activeClassName={styles.active}
          >
            Сайт
          </Link>

          <Link
            to="/about/design"
            className={styles.link}
            activeClassName={styles.active}
          >
            Фирмстиль
          </Link>

          <Link
            to="/blog"
            className={styles.link}
            activeClassName={styles.active}
          >
            Блог
          </Link>
        </div>

        <div className={styles.block}>
          <div className={styles.header}>
            <p>Наши проекты</p>
          </div>
          <div className={styles.projectlinks}>
            <div className={styles.block}>
              {works.slice(0, oneBlockWorks).map((item, index) => {
                return (
                  <Link
                    to={`/${item.slug}`}
                    activeClassName={styles.active}
                    className={styles.link}
                    onClick={() =>
                      width > 1024
                        ? nextWorkRef.current(item.id)
                        : setidChange(item.id)
                    }
                    key={`project_${index}`}
                  >
                    {item.short}
                  </Link>
                );
              })}
            </div>
            <div className={styles.block}>
              {works.slice(twoBlockWorks + 1).map((item, index) => {
                return (
                  <Link
                    to={`/${item.slug}`}
                    activeClassName={styles.active}
                    className={styles.link}
                    onClick={() =>
                      width > 1024
                        ? nextWorkRef.current(item.id)
                        : setidChange(item.id)
                    }
                    key={`project_${index}`}
                  >
                    {item.short}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.header}>
            <p>Контакты</p>
          </div>
          <a
            href="https://vk.com/heycoddes"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Вконтакте
          </a>
          <a
            href="https://wa.me/89538533877"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            WhatsApp
          </a>
          <a
            href="https://t.me/KateShmidt"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Telegram
          </a>
          <a
            href="https://www.behance.net/HeyCoddes"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Behance
          </a>
          <a
            href="https://www.linkedin.com/in/kate-shmidt-362576188/"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Linkedin
          </a>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Links;
