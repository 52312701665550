import React, { useRef, forwardRef } from "react";
import { motion } from "framer-motion";
import { FaWhatsapp, FaTelegram } from "react-icons/fa";
import Lottie from "lottie-react";
import completeLottie from "../../data/animate/completeform.json";
import errorLottie from "../../data/animate/cat404.json";

import { links } from "../../data/links";

import * as styles from "./complete.module.scss";

const Links = ({ whatsapp, telegram }) => {
  return (
    <div className={styles.items}>
      <a
        href={whatsapp}
        target="_blank"
        rel="noreferrer"
        className={styles.link}
      >
        <FaWhatsapp className={styles.icon} />
      </a>
      <a
        href={telegram}
        target="_blank"
        rel="noreferrer"
        className={styles.link}
      >
        <FaTelegram className={styles.icon} />
      </a>
    </div>
  );
};

const Completed = forwardRef(({ error, iswork }, ref) => {
  const completeRef = useRef();
  const errorRef = useRef();

  const whatsapp = links.filter((item) => item.name === "WhatsApp")[0].src;
  const telegram = links.filter((item) => item.name === "Telegram")[0].src;

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.6, delay: 0.2 } }}
      exit={{ opacity: 0 }}
      key="complete"
      className={styles.container}
    >
      {error ? (
        <div className={styles.message}>
          <Lottie
            autoplay={true}
            animationData={errorLottie}
            lottieRef={errorRef}
            className={styles.error}
            setspeed={3}
            loop={true}
          />
          <h2>Что-то пошло не так...</h2>
          <p>
            Вероятно, из-за непредвиденных проблем с интернетом, мы не смогли
            получить вашу заявку.
          </p>
          <div className={styles.links}>
            <p>
              Напишите нам в вацап или телеграм, с удовольствием ответим на все
              ваши вопросы.
            </p>
            <Links whatsapp={whatsapp} telegram={telegram} />
          </div>
        </div>
      ) : (
        <div className={styles.message}>
          <Lottie
            autoplay={true}
            animationData={completeLottie}
            lottieRef={completeRef}
            className={styles.complete}
            setspeed={3}
            loop={true}
          />
          <h2>Спасибо за заявку</h2>
          <p>
            {iswork.status
              ? "В ближайшее время мы все проанализируем и подготовим наиболее подходящее предложение."
              : iswork.message}
          </p>
          <div className={styles.links}>
            <p>
              Если у вас есть вопросы или дополнения к заявке, напишите нам в
              вацап или телеграм.
            </p>
            <Links whatsapp={whatsapp} telegram={telegram} />
          </div>
        </div>
      )}
    </motion.div>
  );
});

export default Completed;
