import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import {
  useWindowSize,
  useWindowScroll,
  useLocation,
  useEffectOnce,
} from "react-use";
import { useStateContext } from "../context";

import LogoShort from "../logotype/logoShort";
import Menu from "./menu";
import works from "../../data/works.json";
import Links from "./links";
import Form from "../form/form";

import * as styles from "./header.module.scss";

function Header() {
  const location = useLocation();
  const { width, height } = useWindowSize();
  const { idChange, setidChange, openForm, setOpenForm, nextWorkRef } =
    useStateContext();

  const { y } = useWindowScroll();

  const [currentHeight, setCurrentHeight] = useState(0);
  const [isStart, setIsStart] = useState(true);
  const [navShow, setNavShow] = useState(false);
  const [isPost, setIsPost] = useState();
  const [title, setTitle] = useState();

  const [color, setColor] = useState("transparent");
  const [background, setBackground] = useState("transparent");

  const widthHeader =
    width > 600 ? (navShow || openForm ? (navShow ? 800 : 600) : 500) : "100%";
  const widthMenu = width > 600 ? (navShow ? 60 : 125) : "100%";

  useEffectOnce(() => {
    setCurrentHeight(height);
  });

  useEffect(() => {
    setCurrentHeight(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    setTimeout(() => {
      setTitle(document.title.split(" |")[0]);
    }, 50);

    setNavShow(false);

    setIsPost(location.pathname.includes("wall"));

    if (title === "Главная") {
      if (idChange === 0) {
        setBackground("#68aefd");
        setColor("#f6f6f6");
      } else {
        setBackground(works[idChange - 1]?.color);
        setColor(works[idChange - 1]?.colorText);
      }
    } else if (
      title === "О нас" ||
      title === "Сайт" ||
      title === "Фирмстиль" ||
      title === "Блог" ||
      title === "Прайс"
    ) {
      setBackground("#68aefd");
      setColor("white");
    } else {
      setBackground(works[idChange - 1]?.color);
      setColor(works[idChange - 1]?.colorText);
    }
  }, [location, idChange, title]);

  useEffect(() => {
    if (
      (title === "Главная" && width <= 600) ||
      (title === "Главная" && idChange !== 0) ||
      title === "О нас" ||
      title === "Сайт" ||
      title === "Фирмстиль" ||
      title === "Блог" ||
      title === "Прайс"
    ) {
      setIsStart(false);
    } else {
      if (y > currentHeight * 0.35) {
        setIsStart(false);
      } else {
        setIsStart(true);
      }
    }
  }, [y, title, idChange, width, currentHeight]);

  return (
    <>
      <AnimatePresence initial={false}>
        {(navShow || openForm) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className={styles.background}
            onClick={() => {
              setNavShow(false);
              setOpenForm(false);
            }}
          />
        )}
      </AnimatePresence>

      <motion.header
        animate={{
          y: isPost || isStart ? (isPost ? 80 : 20) : 0,
          opacity: isPost || isStart ? 0 : 1,
          transition: { duration: 0.6, ease: [0.37, 0.61, 0.4, 0.98] },
        }}
        className={styles.container}
      >
        <motion.div
          initial={{
            width: width > 1024 ? 178 : widthHeader,
            paddingRight: 178,
          }}
          animate={{
            width: widthHeader,
            paddingRight: navShow ? 4 : width > 600 ? 178 : 168,
            marginRight:
              width > 1024 ? 0 : isStart || currentHeight * 1.5 > y ? 0 : 58,
            transition: {
              duration: 0.6,
              ease: [0.37, 0.61, 0.4, 0.98],
            },
            opacity: openForm ? 0 : 1,
          }}
          className={styles.navigation}
        >
          <motion.div
            className={styles.main}
            animate={{
              borderRadius: navShow
                ? "12px 12px 12px 12px"
                : "12px 6px 6px 12px",
            }}
            style={{ color: color, backgroundColor: background }}
          >
            <div className={styles.left}>
              <Link
                to="/"
                onClick={() =>
                  width > 1024
                    ? title === "Главная" && nextWorkRef.current(0)
                    : title === "Главная" && setidChange(0)
                }
                aria-label="Вернуться домой"
                className={styles.logo}
              >
                <LogoShort />
              </Link>
              <motion.div
                animate={{
                  opacity: navShow ? 0 : 1,
                  transition: { duration: 0.4 },
                }}
                className={styles.actualpage}
              >
                <p>{title}</p>
              </motion.div>
            </div>

            <div className={styles.mobileline} />

            <motion.div animate={{ width: widthMenu }} className={styles.menu}>
              <Menu
                navShow={navShow}
                setNavShow={setNavShow}
                color={color}
                background={background}
              />
            </motion.div>
          </motion.div>

          <motion.div className={styles.callback}>
            <button
              className={styles.link}
              style={{ color: color, backgroundColor: background }}
              onClick={() => setOpenForm(!openForm)}
            >
              <p>Заказать дизайн</p>
            </button>
          </motion.div>
        </motion.div>

        <AnimatePresence initial={false}>
          {openForm && (
            <Form
              key="form"
              setOpenForm={setOpenForm}
              color={color}
              background={background}
              isStart={isStart}
              y={y}
            />
          )}
        </AnimatePresence>
        <AnimatePresence initial={false}>
          {navShow && <Links key="linklist" />}
        </AnimatePresence>
      </motion.header>
    </>
  );
}

export default Header;
