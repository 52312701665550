exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-design-js": () => import("./../../../src/pages/about/design.js" /* webpackChunkName: "component---src-pages-about-design-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-web-js": () => import("./../../../src/pages/about/web.js" /* webpackChunkName: "component---src-pages-about-web-js" */),
  "component---src-pages-autovoz-js": () => import("./../../../src/pages/autovoz.js" /* webpackChunkName: "component---src-pages-autovoz-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cleanponytail-js": () => import("./../../../src/pages/cleanponytail.js" /* webpackChunkName: "component---src-pages-cleanponytail-js" */),
  "component---src-pages-domaktera-js": () => import("./../../../src/pages/domaktera.js" /* webpackChunkName: "component---src-pages-domaktera-js" */),
  "component---src-pages-ermitage-js": () => import("./../../../src/pages/ermitage.js" /* webpackChunkName: "component---src-pages-ermitage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isense-js": () => import("./../../../src/pages/isense.js" /* webpackChunkName: "component---src-pages-isense-js" */),
  "component---src-pages-kosindra-js": () => import("./../../../src/pages/kosindra.js" /* webpackChunkName: "component---src-pages-kosindra-js" */),
  "component---src-pages-milota-js": () => import("./../../../src/pages/milota.js" /* webpackChunkName: "component---src-pages-milota-js" */),
  "component---src-pages-neurovision-js": () => import("./../../../src/pages/neurovision.js" /* webpackChunkName: "component---src-pages-neurovision-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-projectfive-js": () => import("./../../../src/pages/projectfive.js" /* webpackChunkName: "component---src-pages-projectfive-js" */),
  "component---src-pages-renome-js": () => import("./../../../src/pages/renome.js" /* webpackChunkName: "component---src-pages-renome-js" */),
  "component---src-pages-typeme-js": () => import("./../../../src/pages/typeme.js" /* webpackChunkName: "component---src-pages-typeme-js" */),
  "component---src-pages-veros-js": () => import("./../../../src/pages/veros.js" /* webpackChunkName: "component---src-pages-veros-js" */),
  "component---src-pages-zakaz-js": () => import("./../../../src/pages/zakaz.js" /* webpackChunkName: "component---src-pages-zakaz-js" */)
}

