import React, { useState, useEffect, lazy, Suspense } from "react";
import { useWindowSize } from "react-use";
import { ReactLenis } from "@studio-freight/react-lenis";
import { ContextProvider } from "./context";

import Header from "./header/header";
import ScrollStart from "./scrollStart";

const ProjectsOnCanvas = lazy(() =>
  import("./projectlist/canvas/projectsOnCanvas")
);

const options = {
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  direction: "vertical",
  gestureDirection: "vertical",
  smooth: true,
  mouseMultiplier: 1,
  smoothTouch: false,
  touchMultiplier: 2,
  infinite: false,
};

export default function Layout({ children }) {
  const { width } = useWindowSize();
  const [currentWidth, setCurrentWidth] = useState(width);

  useEffect(() => {
    if (width !== currentWidth) {
      if (width >= 1024 && currentWidth < 1024) {
        setCurrentWidth(width);
        window.location.reload();
      } else if (width < 1024 && currentWidth >= 1024) {
        setCurrentWidth(width);
        window.location.reload();
      } else {
        setCurrentWidth(width);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWidth, width]);

  return (
    <ContextProvider>
      <Header />
      <Suspense>{width > 1024 && <ProjectsOnCanvas />}</Suspense>
      <ReactLenis root options={options}>
        <main>{children}</main>
      </ReactLenis>

      <ScrollStart />
    </ContextProvider>
  );
}
