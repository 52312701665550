import React, { useRef, useEffect } from "react";
import Lottie from "lottie-react";
import { useWindowSize } from "react-use";
import * as styles from "./logoshort.module.scss";

import logo_short from "../../data/animate/logo_dd.json";

function LogoShort() {
  const logoRef = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    const container = logoRef.current.animationContainerRef.current;

    const StartAnimation = () => {
      logoRef.current.setDirection(1);
      logoRef.current.play();
    };

    const BackAnimation = () => {
      logoRef.current.setDirection(-1);
      logoRef.current.play();
    };

    if (width > 1024) {
      container.addEventListener("mouseenter", StartAnimation);
      container.addEventListener("mouseleave", BackAnimation);
    } else if (width <= 1024) {
      container.addEventListener("touchstart", () => StartAnimation());
      container.addEventListener("touchend", () =>
        setTimeout(() => {
          BackAnimation();
        }, 1000)
      );
    } else {
      return;
    }
  }, [logoRef, width]);

  return (
    <Lottie
      animationData={logo_short}
      loop={false}
      autoplay={false}
      initialSegment={[1, 39]}
      lottieRef={logoRef}
      className={styles.container}
    />
  );
}

export default LogoShort;
