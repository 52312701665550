import React from "react";
import { IoTrashOutline, IoReloadOutline, IoAddOutline } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import cx from "classnames";

import * as styles from "./fileupload.module.scss";

const FileUpload = ({ form, setForm }) => {
  const uploadHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileSize = file.size;

      // Проверка на уникальность имени файла и его размера
      const isUniqueName = !form.files.some((f) => f.name === fileName);
      const isUniqueSize = !form.files.some((f) => f.size === fileSize);

      if (isUniqueName || isUniqueSize) {
        file.isUploading = true;
        setForm((prevState) => ({
          ...prevState,
          files: [...prevState.files, file],
        }));
      } else {
        // Обработка случая, когда имя файла или его размер уже существуют
        console.log("Имя файла или его размер уже существуют");
      }
    } else {
      return;
    }
  };

  const removeFile = (indexFile) => {
    setForm((prevState) => ({
      ...prevState,
      files: prevState.files.filter((_, index) => index !== indexFile),
    }));
  };

  // анимация для файл инпут
  const fileVariant = {
    initial: { opacity: 0, x: 25 },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.15,
        duration: 0.4,
        ease: [0.72, 0.25, 0.58, 0.83],
      },
    },
    exit: {
      opacity: 0,
      y: 15,
      transition: {
        duration: 0.8,
        ease: [0.3, 0.4, 0.58, 1],
      },
    },
  };

  return (
    <div className={cx(styles.upload, form.files.length > 0 && styles.active)}>
      <span className={styles.label}>Приложить файлы</span>
      <div className={cx(styles.add, form.files.length >= 3 && styles.full)}>
        <input
          type="file"
          style={{ pointerEvents: form.files.length >= 3 && "none" }}
          onChange={uploadHandler}
          accept="image/png,image/jpeg,image/jpg,application/pdf"
        />

        <span className={styles.more}>
          <IoAddOutline />
        </span>
      </div>
      <motion.div
        variants={fileVariant}
        initial="initial"
        animate="animate"
        exit="exit"
        className={cx(styles.listfiles, form.files.length > 0 && styles.active)}
      >
        <AnimatePresence mode="popLayout">
          {form.files.map((item, index) => {
            const { name, size, isUploading } = item;
            return (
              <motion.div
                key={`${size}_${name}`}
                layout="position"
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    bounce: 0.3,
                    delay: index === 0 ? 0.4 : 0,
                  },
                }}
                exit={{ y: -5, opacity: 0 }}
                initial={{ opacity: 0, x: 10, y: 0 }}
                transition={{ type: "spring", bounce: 0.3 }}
                className={styles.fileitem}
              >
                <div className={styles.item}>
                  <p>{name}</p>
                </div>
                {isUploading && (
                  <button
                    type="button"
                    className={styles.deleteitem}
                    onClick={() => removeFile(index)}
                  >
                    <IoTrashOutline className={styles.svg} />
                  </button>
                )}
                {!isUploading && (
                  <div className={styles.loaditem}>
                    <IoReloadOutline className={styles.svg} />
                  </div>
                )}
              </motion.div>
            );
          })}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default FileUpload;
