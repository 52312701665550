import React, { createContext, useContext, useState, useRef } from "react";

const StateContext = createContext({
  idChange: 0,
  openForm: false,
  nextWorkRef: () => {},
  setidChange: () => {},
  setOpenForm: () => {},
});

export const ContextProvider = ({ children }) => {
  const [idChange, setidChange] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const nextWorkRef = useRef(() => {});

  return (
    <StateContext.Provider
      value={{
        idChange,
        openForm,
        nextWorkRef,
        setidChange,
        setOpenForm,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
