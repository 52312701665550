// extracted by mini-css-extract-plugin
export var accept = "form-module--accept--27035";
export var backtosite = "form-module--backtosite--fb05c";
export var button = "form-module--button--fcf7f";
export var close = "form-module--close--8d227";
export var container = "form-module--container--a04b6";
export var description = "form-module--description--bc57c";
export var directions = "form-module--directions--8d179";
export var form = "form-module--form--91146";
export var formSubmit = "form-module--formSubmit--81645";
export var input = "form-module--input--9618e";
export var item = "form-module--item--14edd";
export var label = "form-module--label--500bd";
export var loading = "form-module--loading--b077f";
export var name = "form-module--name--a49b3";
export var navigate = "form-module--navigate--3dca6";
export var selected = "form-module--selected--7cd38";
export var send = "form-module--send--4e4b3";
export var svg = "form-module--svg--0b954";
export var title = "form-module--title--ede7a";