// extracted by mini-css-extract-plugin
export var actualpage = "header-module--actualpage--7b5db";
export var background = "header-module--background--e36a6";
export var callback = "header-module--callback--9414b";
export var container = "header-module--container--3b90c";
export var design = "header-module--design--36862";
export var left = "header-module--left--9e4a1";
export var link = "header-module--link--fac36";
export var logo = "header-module--logo--580a1";
export var main = "header-module--main--fa88d";
export var menu = "header-module--menu--84c0c";
export var mobileline = "header-module--mobileline--6e266";
export var navigation = "header-module--navigation--462d0";
export var showheader = "header-module--showheader--bda97";