import React, { useState, useEffect } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { IoArrowDownOutline } from "react-icons/io5";
import {
  useWindowScroll,
  useWindowSize,
  useEffectOnce,
  useLocation,
} from "react-use";

import works from "../data/works.json";
import { useStateContext } from "../components/context";

import * as styles from "./scrollstart.module.scss";

function ScrollStart() {
  const { idChange, openForm } = useStateContext();
  const { scrollYProgress } = useScroll();
  const { y } = useWindowScroll();
  const { height, width } = useWindowSize();
  const location = useLocation();

  const [color, setColor] = useState("transparent");
  const [background, setBackground] = useState("transparent");

  const [title, setTitle] = useState();
  const [currentHeight, setCurrentHeight] = useState(0);
  const [isStart, setIsStart] = useState(true);

  const yRange = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  useEffect(() => {
    if (y > currentHeight * 1.5) {
      setIsStart(false);
    } else {
      setIsStart(true);
    }
  }, [y, currentHeight]);

  useEffect(() => {
    setTimeout(() => {
      setTitle(document.title.split(" |")[0]);
    }, 50);

    if (title === "Главная") {
      if (idChange === 0) {
        setBackground("#68aefd");
        setColor("#f6f6f6");
      } else {
        setBackground(works[idChange - 1]?.color);
        setColor(works[idChange - 1]?.colorText);
      }
    } else if (
      title === "О нас" ||
      title === "Сайт" ||
      title === "Фирмстиль" ||
      title === "Блог" ||
      title === "Прайс"
    ) {
      setBackground("#68aefd");
      setColor("white");
    } else {
      setBackground(works[idChange - 1]?.color);
      setColor(works[idChange - 1]?.colorText);
    }
  }, [location, idChange, title]);

  useEffectOnce(() => {
    setCurrentHeight(height);
  });

  useEffect(() => {
    setCurrentHeight(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <motion.div
      className={styles.container}
      onClick={() =>
        window.scrollTo({
          top: isStart || openForm ? currentHeight - currentHeight * 0.12 : 0,
          behavior: "smooth",
        })
      }
      initial={{ opacity: 0, y: 15 }}
      animate={{
        opacity: isStart || openForm ? 0 : 1,
        y: isStart || openForm ? 15 : 0,
        transition: {
          delay: openForm ? 0 : 0.3,
          duration: 0.6,
          ease: [0.33, 1, 0.68, 1],
        },
      }}
      style={{
        zIndex: isStart ? 1 : 25,
        pointerEvents: openForm ? "none" : "auto",
      }}
    >
      <div
        className={styles.button}
        style={{ background: background, color: color }}
      >
        <svg className={styles.progressIcon}>
          <motion.rect
            stroke={color}
            rx={8}
            height={36}
            width={36}
            y={5}
            x={5}
            strokeWidth={2}
            fill="none"
            style={{
              pathLength,
            }}
          />
        </svg>
        <IoArrowDownOutline
          style={{
            color: color,
          }}
          className={styles.arrow}
        />
      </div>
    </motion.div>
  );
}

export default ScrollStart;
