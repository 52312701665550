import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import cx from "classnames";
import * as styles from "./menu.module.scss";

function Menu({ navShow, setNavShow }) {
  return (
    <button
      className={cx(styles.button, navShow && styles.close)}
      onClick={() => setNavShow(!navShow)}
    >
      <motion.div layout="position" key="menubutton" className={styles.icon}>
        <motion.div
          animate={{
            rotateZ: navShow ? 45 : 0,
            y: navShow ? 5 : 0,
          }}
          className={styles.iconline}
        />
        <motion.div
          animate={{
            opacity: navShow ? 0 : 1,
            x: navShow ? "25%" : 0,
          }}
          className={styles.iconline}
        />
        <motion.div
          animate={{
            rotateZ: navShow ? -45 : 0,
            y: navShow ? -5 : 0,
          }}
          className={styles.iconline}
        />
      </motion.div>
      <AnimatePresence initial={false} mode="popLayout">
        {!navShow && (
          <motion.p
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            key="opennav"
          >
            Меню
          </motion.p>
        )}
      </AnimatePresence>
    </button>
  );
}

export default Menu;
