import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowSize } from "react-use";
import axios from "axios";
import cx from "classnames";
import { IoClose } from "react-icons/io5";

import Completed from "./completed";
import FileUpload from "./fileUpload";

import * as styles from "./form.module.scss";

// Отправка сообщения в телеграм
const TOKEN = `${process.env.GATSBY_TELEGRAM_TOKEN}`;
const CHAT_ID = `${process.env.GATSBY_TELEGRAM_CHAT_ID}`;
const SEND_DOCUMENT_API = `https://api.telegram.org/bot${TOKEN}/sendDocument`;
const SEND_MESSAGE_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

const directionsOptions = [
  { id: "site", value: "Сайт" },
  { id: "firmstyle", value: "Фирменный стиль" },
  { id: "logo", value: "Логотип" },
  { id: "naming", value: "Нейминг" },
  { id: "promo", value: "Промо ролик" },
  { id: "photo", value: "Фото" },
  { id: "other", value: "Другое" },
];

function HeaderForm({ setOpenForm, isStart, y }) {
  const formRef = useRef(null);
  const completeRef = useRef(null);

  const { height, width } = useWindowSize();
  const [form, setForm] = useState({
    directions: {
      firmstyle: false,
      logo: false,
      site: false,
      photo: false,
      promo: false,
      naming: false,
      other: false,
    },
    about: "",
    contact: "",
    name: "Дмитрий",
    files: [],
    complete: false,
    error: false,
    loading: false,
    iswork: {
      status: true,
      message: "",
    },
  });

  const setSelected = (direction) => () =>
    setForm((prevState) => ({
      ...prevState,
      directions: {
        ...prevState.directions,
        [direction]: !prevState.directions[direction],
      },
    }));

  const sendDocuments = async (files) => {
    try {
      for (const item of files) {
        const fileData = new FormData();
        fileData.append("chat_id", CHAT_ID);
        fileData.append("document", item);

        await axios.post(SEND_DOCUMENT_API, fileData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setForm((prevState) => ({ ...prevState, loading: true }));

    const directionsArray = Object.entries(form.directions)
      .filter(([key, value]) => value)
      .map(
        ([key, value]) =>
          directionsOptions.find((option) => option.id === key).value
      );

    const directions =
      directionsArray.length > 0
        ? directionsArray.join(", ") + "."
        : "Не выбраны.";

    const messageText =
      "Заявка с сайта | Hey, Coddes" +
      "\n" +
      `<b>Направления:</b> ${directions}` +
      "\n" +
      `<b>О проект / бюджет:</b> ${form.about}` +
      "\n" +
      `<b>Телефон / Мессенджер:</b> ${form.contact}` +
      "\n" +
      `<b>Имя:</b> ${form.name}`;

    const messageData = new FormData();
    messageData.append("chat_id", CHAT_ID);
    messageData.append("parse_mode", "HTML");
    messageData.append("text", messageText);

    try {
      await axios.post(SEND_MESSAGE_API, messageData);
      setForm((prevState) => ({
        ...prevState,
        complete: true,
        loading: false,
        error: false,
      }));
    } catch (err) {
      console.log(err);
      setForm((prevState) => ({
        ...prevState,
        complete: false,
        loading: false,
        error: true,
      }));
    }

    if (form.files.length > 0) {
      await sendDocuments(form.files);
    }
  };

  useEffect(() => {
    // Функция для получения приветственного сообщения на основе текущего времени
    const getWelcomeMessage = (hours) => {
      if (hours >= 18 && hours < 22) {
        return "Наш рабочий день закончился, но мы обязательно дадим обратную связь в начале следующего рабочего дня!";
      } else if (hours >= 22 || hours < 7) {
        return "У нас уже ночь, но мы обязательно дадим обратную связь по вашему проекту в начале следующего рабочего дня!";
      } else if (hours >= 7 && hours < 10) {
        return "Наш рабочий день скоро начнется и мы сразу же дадим обратную связь по вашему проекту!";
      } else {
        return ""; // Возвращаем пустую строку, если никакое условие не выполнено
      }
    };

    // Асинхронная функция для получения данных о времени
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://worldtimeapi.org/api/timezone/Asia/Krasnoyarsk"
        );
        const date = new Date(response.data.datetime);
        const hours = date.getHours();

        // Получаем приветственное сообщение
        const message = getWelcomeMessage(hours);

        // Устанавливаем состояние
        setForm((prevState) => ({
          ...prevState,
          iswork: {
            status: hours >= 10 && hours < 18,
            message: message,
          },
        }));
      } catch (error) {
        console.error("Ошибка запроса времени:", error);
      }
    };

    fetchData();
  }, []);

  console.log("form: ", completeRef);

  useEffect(() => {
    console.log(formRef);
  }, [formRef]);

  return (
    <motion.div
      animate={{
        width: width > 1024 ? 600 : "100%",
        transition: {
          duration: 0.6,
          ease: [0.37, 0.61, 0.4, 0.98],
        },
      }}
      initial={{ width: 0 }}
      exit={{
        opacity: 0,
        width: 400,
        transition: {
          delay: 0.2,
          duration: 0.4,
          ease: [0.37, 0.61, 0.4, 0.98],
        },
      }}
      className={styles.container}
    >
      <div className={styles.form} style={{ maxHeight: height - 105 }}>
        <AnimatePresence mode="popLayout">
          {form.complete ? (
            <Completed
              {...{ error: form.error, iswork: form.iswork, ref: completeRef }}
              key="formcomplete"
            />
          ) : (
            <motion.form
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="formcontact"
              id="formcontact"
              onSubmit={onSubmit}
              ref={formRef}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { delay: 0.3, duration: 0.6 },
                }}
                exit={{ opacity: 0 }}
              >
                <h2 className={styles.title}>Заказать дизайн</h2>
                <p className={styles.description}>
                  Для начала работы нам надо познакомиться. Достаточно
                  рассказать о своем проекте, указать контакты, и мы свяжемся,
                  чтобы все обсудить.
                </p>
                <div className={cx(styles.input, styles.selected)}>
                  <div className={styles.directions}>
                    {directionsOptions.map(({ id, value }) => (
                      <div className={styles.item} key={id}>
                        <label
                          htmlFor={id}
                          className={styles.label}
                          style={{
                            backgroundColor: form.directions[id]
                              ? "#68aefd"
                              : "#3c3c3b12",
                            color: form.directions[id] ? "white" : "#3c3c3b",
                          }}
                        >
                          <span className={styles.name}>{value}</span>
                          <input
                            className={styles.button}
                            type="checkbox"
                            id={id}
                            value={value}
                            onClick={setSelected(id)}
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={styles.input}>
                  <textarea
                    type="text"
                    required={true}
                    rows="1"
                    autoComplete="off"
                    onChange={(e) =>
                      setForm((prevState) => ({
                        ...prevState,
                        about: e.target.value,
                      }))
                    }
                  />
                  <span className={styles.label}>
                    О проекте / Приблизительный бюджет
                  </span>
                </div>

                <div className={styles.input}>
                  <input
                    type="text"
                    required={true}
                    autoComplete="off"
                    onChange={(e) =>
                      setForm((prevState) => ({
                        ...prevState,
                        contact: e.target.value,
                      }))
                    }
                  />
                  <span className={styles.label}>Телефон / Мессенджер</span>
                </div>

                <div className={styles.input}>
                  <input
                    type="text"
                    required={true}
                    autoComplete="off"
                    onChange={(e) =>
                      setForm((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }))
                    }
                  />
                  <span className={styles.label}>Имя</span>
                </div>

                <FileUpload setForm={setForm} form={form} />

                <p className={styles.accept}>
                  Оставляя заявку Вы соглашаетесь с{" "}
                  <a href="/policy" target="_blank">
                    политикой конфиденциальности
                  </a>
                </p>
              </motion.div>
            </motion.form>
          )}
        </AnimatePresence>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        animate={{
          opacity: 1,
          marginRight: width > 1024 ? 0 : isStart || height * 1.5 > y ? 0 : 62,
          transition: {
            delay: 0,
            duration: 0.4,
            ease: [0.37, 0.61, 0.4, 0.98],
            marginRight: {
              delay: 0,
              duration: 0.6,
              ease: [0.37, 0.61, 0.4, 0.98],
            },
          },
        }}
        className={styles.navigate}
      >
        <div
          className={cx(
            styles.formSubmit,
            (form.loading || form.complete) && styles.loading
          )}
        >
          {form.complete ? (
            <button
              className={styles.backtosite}
              onClick={() => setOpenForm(false)}
            >
              Вернуться к сайту
            </button>
          ) : (
            <button type="submit" form="formcontact" className={styles.send}>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { delay: 0.3, duration: 0.6 },
                }}
              >
                {form.loading
                  ? "Почти готово, заявка в пути!"
                  : "Отправить заявку"}
              </motion.p>
            </button>
          )}

          <button className={styles.close} onClick={() => setOpenForm(false)}>
            <IoClose className={styles.svg} />
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default HeaderForm;
