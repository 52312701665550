export const links = [
  {
    name: "Вконтакте",
    src: "https://vk.com/heycoddes",
    icon: "/images/social/vk.svg",
    alt: "Иконка вконтакте",
    color: "#597da3",
  },
  {
    name: "WhatsApp",
    src: "https://wa.me/+79538533877",
    icon: "/images/social/whatsapp.svg",
    alt: "Иконка вацап",
    color: "#43d854",
  },
  {
    name: "Telegram",
    src: "https://t.me/KateShmidt",
    icon: "/images/social/telegram.svg",
    alt: "Иконка телеграм",
    color: "#0088cc",
  },
  {
    name: "Behance",
    src: "https://www.behance.net/HeyCoddes",
    icon: "/images/social/behance.svg",
    alt: "Иконка биханс",
    color: "#053eff",
  },
  {
    name: "LinkedIn",
    src: "https://www.linkedin.com/in/kate-shmidt-362576188/",
    icon: "/images/social/linkedin.svg",
    alt: "Иконка linkedin",
    color: "#0e76a8",
  },
];
